import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"value":_vm.showDialog,"persistent":"","max-width":_vm.maxWidth,"scrollable":""}},[_c(VCard,[_c(VCardTitle,{staticClass:"title font-weight-regular justify-space-between"},[_vm._t("title",function(){return [_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]})],2),_c(VCardText,[_vm._t("default")],2),_c(VCardActions,[_vm._t("actions"),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onClose}},[_vm._v("Close")]),(_vm.showConfirm)?_c(VBtn,{attrs:{"color":"blue darken-1","loading":_vm.processing,"text":""},on:{"click":_vm.onConfirm}},[_vm._v(_vm._s(_vm.confirmText))]):_vm._e()],2)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }