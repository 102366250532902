
    import {Component, Prop, Vue} from "vue-property-decorator";
    import {IRouteDialog, RouteDialogConfirmEvent} from "@/domain";

    @Component
    export default class RouteDialog extends Vue implements IRouteDialog {

        @Prop({ type: String, required: true })
        readonly title!: string;

        @Prop({ type: String, default: 'Confirm' })
        readonly confirmText!: string;

        @Prop({ type: Boolean, default: false })
        readonly loading!: boolean;

        @Prop({ type: [String, Number], default: '600px' })
        readonly maxWidth!: string | number;

        visible: boolean = false;
        processing: boolean = false;

        get showConfirm() {
            return !!this.$listeners.confirm;
        }

        get showDialog() {
            return this.visible && !this.loading;
        }

        onConfirm() {
            this.processing = true;

            const evt: RouteDialogConfirmEvent = {
                done: () => {
                    this.processing = false;
                    this.onClose();
                },
                cancel: () => {
                    this.processing = false;
                }
            };

            this.$emit("confirm", evt);
        }

        onClose() {
            this.visible = false;
            if(this.$route.matched.length > 1) {
                setTimeout(() => {
                    this.$router.push({
                        name: this.$route.matched[0].name
                    });
                }, 500);
            }
        }

        mounted() {
            this.visible = true;
        }

        close() {
            this.onClose();
        }
    }
